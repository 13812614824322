import React from "react";
import { either as E } from "fp-ts";

import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Alert,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { AuditTrailFormula } from "../AuditTrailFormula/AuditTrailFormula";
import { MoneyT } from "../AuditTrailFormula/types";

type Props = Readonly<{
  isOpen: boolean;
  trailJson_UNSTABLE?: string;
  onClose: () => void;
}>;

export const AuditTrailDialog: React.FC<Props> = ({
  onClose,
  trailJson_UNSTABLE,
  isOpen,
}) => {
  const handleClose = () => onClose();

  const trail = JSON.parse(trailJson_UNSTABLE ?? "{}");

  const navTrailJson = MoneyT.decode("nav" in trail ? trail.nav : {});

  return (
    <Dialog fullScreen onClose={handleClose} open={isOpen}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            color="inherit"
          >
            Financial Stack Audit Trail
          </Typography>
        </Toolbar>
      </AppBar>
      <Box my={2}>
        <Alert severity="warning">
          The NAV audit trail functionality has been moved to the Admin panel
          and can be found in the Financials section of an Allocation - this
          interface has been deprecated and will be removed soon.
        </Alert>
      </Box>
      {E.isRight(navTrailJson) ? (
        <AuditTrailFormula value={navTrailJson.right} />
      ) : (
        <Alert severity="error">Invalid JSON - cannot decode data</Alert>
      )}
    </Dialog>
  );
};
