import { config } from "./config";
import React, { PropsWithChildren, useMemo } from "react";
import { useInvestor } from "../hooks/useInvestor";
import { isInvestorPending } from "./investor";
import { ContentWrapper } from "../components/layout/ContentWrapper/ContentWrapper";
import { useLayoutContext } from "../routes/LayoutRoute/LayoutRoute";
import { PasswordForm } from "../components/userProfile/PasswordForm/PasswordForm";
import { usePasswordChangeMutation } from "./hooks/usePasswordChangeMutation";
import { useRoutesResolver } from "../lib/hooks/useRoutesResolver";
import { RouteClasses } from "../routes/RootRoute/RootRoute";
import { DashboardRoute, LayoutRouteQuery } from "../generated/urql";
import { NoDataPlaceholder } from "../components/core/v2/NoDataPlaceholder/NoDataPlaceholder";
import * as z from "zod";

// A value schema for the default dashboard page
// that is stored in the local storage.
// This contains both the path reference and a `createdAt`
// field that is used to determine if the default page
// should be reset.
const defaultDashboardPageValueSchema = z.intersection(
  z.object({
    createdAt: z.coerce.date(),
    queryString: z.string().optional(),
  }),
  z.discriminatedUnion("page", [
    z.object({
      page: z.literal("VDR/CapitalCalls"),
      investorId: z.string().optional(),
    }),
    z.object({
      page: z.literal("VDR/Distributions"),
      investorId: z.string().optional(),
    }),
    z.object({ page: z.literal("VDR"), investorId: z.string().optional() }),
    z.object({
      page: z.literal("BankAccounts"),
      investorId: z.string().optional(),
    }),
  ]),
);

const defaultDashboardPageKey = "defaultDashboardPage";

type DefaultDashboardPageValue = z.infer<
  typeof defaultDashboardPageValueSchema
>;

/**
 * Execute a redirect toward the dashboard.
 */
export function redirectToDashboard() {
  window.location.href = config.pathPrefix;
}

/**
 * Set the default dashboard page.
 */
export function setDefaultDashboardPage(
  page: Omit<DefaultDashboardPageValue, "createdAt">,
) {
  const pageValue: DefaultDashboardPageValue = {
    ...page,
    createdAt: new Date(),
  };

  localStorage.setItem(defaultDashboardPageKey, JSON.stringify(pageValue));
}

/**
 * Get the default dashboard page.
 */
export function getDefaultDashboardPage(): DefaultDashboardPageValue | null {
  const page = localStorage.getItem(defaultDashboardPageKey);
  const parsedPage = defaultDashboardPageValueSchema.safeParse(
    JSON.parse(page ?? "{}"),
  );

  if (!parsedPage.success) {
    return null;
  }

  const pageCreatedAt = parsedPage.data.createdAt.getTime();
  const currentTime = new Date().getTime();
  const pageMsValid = 1000 * 60 * 60; // 1 hour.

  // If the default page has been created more than 1 hour ago
  // then it's not valid anymore.
  if (pageCreatedAt < currentTime - pageMsValid) return null;

  return parsedPage.data;
}

/**
 * Remove the default dashboard page.
 */
export function removeDefaultDashboardPage() {
  localStorage.removeItem(defaultDashboardPageKey);
}

/**
 * Guard that will render the children only if the investor is not pending.
 */
export const PendingInvestorGuard: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const investor = useInvestor();
  const isPending = useMemo(
    () => (investor ? isInvestorPending(investor) : undefined),
    [investor],
  );

  if (!isPending) return <>{children}</>;

  return (
    <NoDataPlaceholder message="The selected investor is currently under approval" />
  );
};

/**
 * A guard used to check whether the user has chosen a password or not.
 *
 * If `passwordChangedAt` is undefined we are going to ask the user to change it.
 */
export const PasswordNotChangedGuard: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    authUser: { passwordChangedAt },
    executeQuery: refreshAuthUser,
  } = useLayoutContext();

  const passwordChange = usePasswordChangeMutation();

  if (!passwordChangedAt)
    return (
      <ContentWrapper>
        <PasswordForm
          {...passwordChange}
          onSuccess={refreshAuthUser}
          firstChange
        />
      </ContentWrapper>
    );
  else return <>{children}</>;
};

/**
 * Retrieve the default route path for the current user.
 */
export function useDefaultRoutePath(
  investors: LayoutRouteQuery["authUser"]["investors"],
) {
  const { generatePathForRoute } = useRoutesResolver<RouteClasses>();

  const { investor: firstInvestor } = investors[0]; // TODO: handle case when there are no investors

  const defaultRoute =
    firstInvestor.defaultRoute === DashboardRoute.Assets
      ? "AssetsRoute"
      : "PortfolioRoute";

  const firstInvestorPath = generatePathForRoute(defaultRoute, {
    investorId: firstInvestor.id,
  });

  return firstInvestorPath;
}

/**
 * Concatenate a path with a query string.
 */
export function generatePathWithQueryString(
  path: string,
  queryString?: string,
): string {
  const queryStringParams = new URLSearchParams(queryString);

  return `${path}?${queryStringParams.toString()}`;
}
