import React, { useMemo } from "react";

import { Money } from "@heritageholdings/lib-commons-finance/lib/units/money";

import { formatDateDisplay1 } from "../../../../utils/date";

import { PageTable } from "../../../core/v2/PageTable/PageTable";
import { moneySumAggregationLabel } from "../../../core/v2/Table/moneyTableUtilities";
import {
  DocumentLinkStructure,
  TableColumn,
  TableGroupingColumn,
  tableFallbackValue,
} from "../../../core/v2/Table/Table";

import { SingleDistribution } from "../data/SingleDistribution";
import {
  formatAggregatedFundName,
  formatTaxNature,
  generateDistributionFundAggregationId,
} from "../utils";
import {
  GridApiCommon,
  GridRenderCellParams,
  GridRowId,
  gridDataRowIdsSelector,
} from "@mui/x-data-grid-premium";
import { PaidBadge, generatePaidBadgeText } from "./PaidBadge";
import { P, match } from "ts-pattern";
import { DistributionStatus } from "./data/DistributionStatus";
import { useRecoilValue } from "recoil";
import { enableDistributionStatusBadge } from "../../../../state/developer";
import {
  customFirstValAggregationLabel,
  customSameValAggregationLabel,
} from "../../../core/v2/Table/customTableUtilities";
import { config } from "../../../../utils/config";
import { tracker } from "../../../../utils/tracker";
import { useInvestor } from "../../../../hooks/useInvestor";

type Props = Readonly<{
  distributions: ReadonlyArray<SingleDistribution>;
}>;

type DistributionsRow = {
  path: Array<string>;
  id: string;
  fundName: string;
  valueDate: Date;
  distributed: Money;
  isPaid: boolean;
  currency: string;
  link: DocumentLinkStructure;
  taxNature: string;
};

function getDistributionStatus(
  value: unknown,
  id: GridRowId,
  api: GridApiCommon,
): DistributionStatus {
  const row = api.getRowNode(id);

  if (row?.type !== "group") {
    return value ? "PAID" : "UNPAID";
  }

  const paidTransactions = row.children.filter(
    (id) => api.getRow<DistributionsRow>(id)?.isPaid === true,
  );

  return paidTransactions.length === row.children.length
    ? "PAID"
    : paidTransactions.length > 0
      ? "PARTIALLY_PAID"
      : "UNPAID";
}

/**
 * The distributions section in the transactions page.
 */
export const Distributions: React.FC<Props> = ({ distributions }) => {
  const isDistributionStatusBadgeEnabled = useRecoilValue(
    enableDistributionStatusBadge,
  );
  const investorId = useInvestor()?.id;

  const { rows, aggregatedFunds, fundAggregationIdToName } = useMemo(() => {
    const rows: Array<DistributionsRow> = [];
    const aggregatedFunds: Record<string, Set<string>> = {};
    const fundAggregationIdToName: Record<string, string> = {};

    if (!investorId) return { rows, aggregatedFunds, fundAggregationIdToName };

    distributions.forEach((distribution) => {
      const valueDate = distribution.valueDate;
      const distributionAggregationId = distribution.uuid;

      if (aggregatedFunds[distributionAggregationId] === undefined)
        aggregatedFunds[distributionAggregationId] = new Set();

      // We are going to use this record to aggregate transactions with the same
      // fund and currency in this LP Distribution.
      const aggregatedTransactions: Record<string, DistributionsRow> = {};

      distribution.transactions.forEach((transaction) => {
        const fundName = transaction.allocation.asset.name;

        // FIXME: These types should be handled in a better way in order
        // to avoid the explicit cast.
        const currency = transaction.allocation.currency;

        const fundAggregationId = generateDistributionFundAggregationId(
          fundName,
          currency,
          [transaction.taxNature, transaction.isRecallable],
        );

        const distributed = new Money({
          [currency]: transaction.amount,
        });

        const current: DistributionsRow | undefined =
          aggregatedTransactions[fundAggregationId];

        // If we already have a row with this fund and currency
        // then we just aggregate it to the existing value.
        if (current !== undefined) {
          current.distributed = current.distributed.plus(distributed);
          return;
        }

        // This will be used while rendering the single groups to make
        // the group name, which is composed by a comma separated list
        // of funds names for a specific capital call date.
        aggregatedFunds[distributionAggregationId].add(fundName);

        // This will be used while rendering each group item (leaf) in order
        // to avoid showing the aggregation fund id to the user.
        fundAggregationIdToName[fundAggregationId] = fundName;

        aggregatedTransactions[fundAggregationId] = {
          path: [distributionAggregationId, fundAggregationId],
          id: `${distributionAggregationId}-${fundAggregationId}`,
          fundName: fundName,
          valueDate: new Date(valueDate),
          distributed: distributed,
          isPaid: transaction.isPaid,
          currency,
          taxNature: formatTaxNature(
            transaction.taxNature,
            transaction.isRecallable,
          ),
          link: {
            kind: "download",
            link: config.serverBaseUrl + distribution.document.link,
            onClick: () => {
              tracker.trackEvent({
                name: "ShowDistributionDocument",
                payload: {
                  distributionId: distribution.uuid,
                  investorId: investorId,
                },
              });
            },
          },
        };
      });

      rows.push(...Object.values(aggregatedTransactions));
    });

    return { rows, aggregatedFunds, fundAggregationIdToName };
  }, [distributions, investorId]);

  const columns = useMemo<Array<TableColumn<DistributionsRow>>>(
    () => [
      {
        headerName: "Fund name",
        field: "fundName",
        flex: 1,
      },
      {
        headerName: "Value Date",
        field: "valueDate",
        type: "date",
        minWidth: 150,
        valueFormatter: (value, { id }, _, api) => {
          const valueToDisplay = match([value, id])
            .with([P.nullish, P.nullish], () => null)
            .with([P.instanceOf(Date), P.nullish], ([dateValue]) =>
              formatDateDisplay1(dateValue as Date),
            )
            .with([P.instanceOf(Date), P.string], ([dateValue, rowId]) => {
              const row = api.current.getRowNode(rowId);

              // Hide the date if this is a leaf node.
              if (row?.type === "leaf") return null;

              return formatDateDisplay1(dateValue as Date);
            })
            .otherwise(() => tableFallbackValue);

          return valueToDisplay;
        },
        flex: 1,
      },
      {
        headerName: "Tax nature",
        field: "taxNature",
        flex: 1,
        type: "custom",
      },
      {
        headerName: "Distribution Amount",
        type: "Money",
        field: "distributed",
        minWidth: 200,
        flex: 1,
      },
      ...((isDistributionStatusBadgeEnabled
        ? [
            {
              headerName: "Status",
              field: "isPaid",
              valueFormatter: (value, row, _, api) => {
                if (!api.current) return null;

                const status = getDistributionStatus(
                  value,
                  row.id,
                  api.current,
                );
                return generatePaidBadgeText(status);
              },
              renderCell: ({
                api,
                id,
                value,
              }: GridRenderCellParams<DistributionsRow>) => {
                const status = getDistributionStatus(value, id, api);
                return <PaidBadge status={status} />;
              },
            },
          ]
        : []) satisfies Array<TableColumn<DistributionsRow>>),
      {
        headerName: "",
        field: "link",
        type: "custom",
        width: 55,
        customColumnKind: {
          kind: "documentLink",
          generateLink: ({ value }) => value as DocumentLinkStructure,
          hideOnNodeGroup: "leaf",
        },
      },
    ],
    [isDistributionStatusBadgeEnabled],
  );

  const groupingColumn = useMemo<TableGroupingColumn<DistributionsRow>>(
    () => ({
      flex: 3,
      minWidth: 200,
      headerName: "Funds",
      valueFormatter: (value) => {
        // If the value is not `undefined` then this is a
        // group and we should format it.
        if (typeof value !== "undefined") {
          return typeof value === "string" && aggregatedFunds[value]
            ? (formatAggregatedFundName(aggregatedFunds[value]) ??
                tableFallbackValue)
            : tableFallbackValue;
        }

        // If otherwise the value IS `undefined` then this is
        // a normal row, and we should return the base value.
        return value;
      },
      formatLeafValue: (value) =>
        typeof value === "string" ? fundAggregationIdToName[value] : value,
    }),
    [aggregatedFunds, fundAggregationIdToName],
  );

  return (
    <PageTable
      title="Distributions"
      kind="table"
      columns={columns}
      rows={rows}
      groupingColumn={groupingColumn}
      aggregationModel={{
        valueDate: "max",
        distributed: moneySumAggregationLabel,
        link: customFirstValAggregationLabel,
        taxNature: customSameValAggregationLabel,
      }}
      visibilityModel={{
        fundName: false,
      }}
      excelGetRowsToExport={(apiRef) => {
        const rows = gridDataRowIdsSelector(apiRef);
        return rows.filter((id) => id !== undefined);
      }}
      defaultSortingModel={{
        field: "valueDate",
        sort: "desc",
      }}
      exportHidden
      treeData
      downloadExcel
    />
  );
};
