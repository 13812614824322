import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { Box } from "../../../core/v2/Box/Box";
import { Button } from "../../../core/v2/Button/Button";
import { DownloadIcon } from "../../../core/v2/Icon/Icon";

type Props = {
  vehicleName: string;
  downloadLink: string;
  onDownload?: () => void;
};

export const HighlightedDocument: React.FC<Props> = ({
  vehicleName,
  downloadLink,
  onDownload,
}) => {
  return (
    <Paper>
      <Stack
        p={2}
        direction={{ md: "row" }}
        alignItems={{ md: "center" }}
        justifyContent="space-between"
        gap={2}
      >
        <Typography>
          A new document has been issued regarding your investments in{" "}
          <strong>{vehicleName}</strong>
        </Typography>

        <Box>
          <Button
            href={downloadLink}
            variant="primary"
            LeftIcon={DownloadIcon}
            onClick={onDownload}
          >
            Download
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
};
