import { useSearchParams } from "react-router-dom";

export const highlightedDocumentParamName = "highlighted";

/**
 * Return whether there is an highlighted document to show.
 */
export const useHighlightedDocument = (): string | undefined => {
  const [params] = useSearchParams();
  const highlightedDocument = params.get(highlightedDocumentParamName);

  return highlightedDocument ?? undefined;
};
