import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import { datadogRum } from "@datadog/browser-rum";

import {
  LayoutRouteComponent,
  LayoutRouteQuery,
  Subscription,
  SystemStatus,
} from "../../generated/urql";

import { useRoutesResolver } from "../../lib/hooks/useRoutesResolver";

import { RouteClasses } from "../RootRoute/RootRoute";

import { OfflineBanner } from "../../components/commons/OfflineBanner";
import { FullPageLoader } from "../../components/core/Loader/FullPageLoader";
import { SidebarDrawer as SidebarDrawerV2 } from "../../components/layout/v2/SidebarDrawer/SidebarDrawer";

import { useSystemStatusSubscription } from "../../hooks/useSystemStatusSubscription";

import { ExecuteQuery, handleQueryData } from "../../utils/queries";
import { tracker } from "../../utils/tracker";
import { Navbar } from "../../components/layout/v2/Navbar/Navbar";
import { ThemeProvider, Toolbar } from "@mui/material";
import {
  generatePathWithQueryString,
  getDefaultDashboardPage,
  removeDefaultDashboardPage,
  useDefaultRoutePath,
} from "../../utils/navigation";
import { Box } from "../../components/core/v2/Box/Box";
import { getTheme } from "../../mui/theme";
import { NotificationsProvider } from "../../utils/notifications";
import {
  documentsCapitalCallsRouteName,
  documentsDistributionsRouteName,
} from "../../components/documents/v2/DataRoomView/DataRoomView";

type ContextProps = Readonly<{
  systemStatus?: Subscription["system"];
}>;

type LayoutProps = Readonly<{
  authUser: LayoutRouteQuery["authUser"];
  configuration: LayoutRouteQuery["configuration"];
  executeQuery: ExecuteQuery;
}>;

/**
 * Wraps a route with a layout comprised of a sidebar menu.
 *
 * The sidebar menu is populated with links to the sub-sections of the
 * application for an investor and also links to navigate to all the
 * investors associated with the current user.
 */
export const LayoutRoute: React.FC<LayoutProps> = ({
  authUser,
  configuration,
  executeQuery,
}) => {
  const { getRouteForPath, generatePathForRoute } =
    useRoutesResolver<RouteClasses>();
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = useMemo(
    () => getRouteForPath(location.pathname),
    [getRouteForPath, location.pathname],
  );
  const [drawerOpen, setDrawerOpen] = useState(false);

  // All the investors associated to the current user.
  const { investors } = authUser;

  // The selected investor ID from the route.
  const { investorId: currentInvestorId } = useParams();
  const systemStatus = useSystemStatusSubscription();

  const defaultRoutePath = useDefaultRoutePath(authUser.investors);

  useEffect(() => {
    datadogRum.setUser({
      id: authUser.id,
      name: [authUser.firstName, authUser.lastName.substring(0, 1) ?? ""].join(
        ", ",
      ),
    });

    tracker.identifyUser({
      userId: authUser.id,
      email: authUser.email,
      firstName: authUser.firstName,
      lastName: authUser.lastName,
    });
  }, [authUser]);

  useEffect(() => {
    tracker.trackEvent({
      name: "Navigation",
      payload: { screen: currentRoute ?? "" },
    });
  }, [currentRoute]);

  // Handle the initial redirect toward a saved page
  // in the user's storage.
  useEffect(() => {
    if (!currentInvestorId) return;

    const defaultDashboardPage = getDefaultDashboardPage();

    // Remove if we have a saved page.
    removeDefaultDashboardPage();

    const investorId = defaultDashboardPage?.investorId ?? currentInvestorId;

    switch (defaultDashboardPage?.page) {
      case "VDR":
        navigate(
          generatePathWithQueryString(
            generatePathForRoute("DocumentsRoute", {
              investorId,
            }),
            defaultDashboardPage?.queryString,
          ),
        );
        break;

      case "VDR/CapitalCalls":
        navigate(
          generatePathWithQueryString(
            generatePathForRoute("DocumentsRoute", {
              investorId,
            }) + `/${documentsCapitalCallsRouteName}`,
            defaultDashboardPage?.queryString,
          ),
        );
        break;

      case "VDR/Distributions":
        navigate(
          generatePathWithQueryString(
            generatePathForRoute("DocumentsRoute", {
              investorId,
            }) + `/${documentsDistributionsRouteName}`,
            defaultDashboardPage?.queryString,
          ),
        );
        break;

      case "BankAccounts":
        navigate(
          generatePathWithQueryString(
            generatePathForRoute("TransactionsRoute", {
              investorId,
            }),
            defaultDashboardPage?.queryString,
          ),
        );
        break;
    }
  }, [navigate, generatePathForRoute, currentInvestorId]);

  const handleInvestorChange = useCallback(
    (investorId: string) => {
      const currentPath = getRouteForPath(location.pathname);

      // FIXME: This is not _really_ safe and we should
      // find a better way to handle type-safe dynamic
      // redirects.
      const safeCurrentPath = (currentPath ?? "PortfolioRoute") as RouteClasses;

      const newPath = generatePathForRoute(safeCurrentPath, {
        investorId,
      });

      navigate(newPath);
    },
    [generatePathForRoute, navigate, getRouteForPath, location.pathname],
  );

  const maybeCurrentUserInvestor = investors.find(
    ({ investor: { id } }) => id === currentInvestorId,
  );

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  const handleDrawerClose = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  const theme = useMemo(() => getTheme(), []);

  return (
    <ThemeProvider theme={theme}>
      <NotificationsProvider isThemeV2Enabled={true}>
        <Navbar
          authUser={authUser}
          currentInvestorId={currentInvestorId}
          drawerOpen={drawerOpen}
          currentRoute={currentRoute}
          onMenuPress={drawerOpen ? handleDrawerClose : handleDrawerOpen}
          onInvestorChange={handleInvestorChange}
          logoLink={defaultRoutePath}
        />

        <Box
          sx={(theme) => ({
            display: "flex",
            minHeight: `calc(100vh - ${theme.spacing(3)})`,
            backgroundColor: theme.palette.heritageV2.backgroundPage,
            px: 3,
            mt: 3,
          })}
          minHeight="100%"
        >
          {systemStatus !== SystemStatus.Maintenance && (
            <SidebarDrawerV2
              authUser={authUser}
              selectedUserInvestor={maybeCurrentUserInvestor}
              userInvestors={investors}
              currentRoute={currentRoute}
              drawerOpen={drawerOpen}
              onDrawerClose={handleDrawerClose}
              onDrawerOpen={handleDrawerOpen}
            />
          )}
          <Box
            display="flex"
            component="main"
            flexGrow={1}
            minHeight="100%"
            flexDirection="column"
            // Bottom padding in order to give breathing room
            // to the bottom of the page.
            pb="10em"
          >
            <Suspense fallback={<FullPageLoader />}>
              <Box flex="0 0 auto">
                <Toolbar />
              </Box>

              <Box flex="1 1 100%">
                <Outlet
                  context={{
                    configuration,
                    authUser,
                    systemStatus,
                    executeQuery,
                  }}
                />
              </Box>
            </Suspense>
          </Box>
          <OfflineBanner />
        </Box>
      </NotificationsProvider>
    </ThemeProvider>
  );
};

export const useLayoutContext = () =>
  useOutletContext<LayoutProps & ContextProps & ExecuteQuery>();

export const AppLayoutLoader: React.FC = () => (
  <LayoutRouteComponent
    children={handleQueryData((data, executeQuery) => (
      <LayoutRoute {...data} executeQuery={executeQuery} />
    ))}
  />
);
