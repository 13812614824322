import React, { useMemo } from "react";

import { Money } from "@heritageholdings/lib-commons-finance/lib/units/money";

import { formatDateDisplay1 } from "../../../../utils/date";
import { parseSimpleMoney } from "../../../../utils/data/SimpleMoney";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";

import { PageTable } from "../../../core/v2/PageTable/PageTable";
import { moneySumAggregationLabel } from "../../../core/v2/Table/moneyTableUtilities";
import {
  DocumentLinkStructure,
  TableColumn,
  TableGroupingColumn,
  tableFallbackValue,
} from "../../../core/v2/Table/Table";

import { Stack, Typography } from "@mui/material";
import {
  GridRenderCellParams,
  gridDataRowIdsSelector,
} from "@mui/x-data-grid-premium";
import { Chip } from "../../../core/v2/Chip/Chip";
import { TooltipWrapper } from "../../../core/v2/TooltipWrapper/TooltipWrapper";
import { SingleCapitalCall } from "../data/SingleCapitalCall";
import {
  formatAggregatedFundName,
  generateCapitalCallFundAggregationId,
} from "../utils";
import { P, match } from "ts-pattern";
import { config } from "../../../../utils/config";
import { tracker } from "../../../../utils/tracker";
import { useInvestor } from "../../../../hooks/useInvestor";
import { customFirstValAggregationLabel } from "../../../core/v2/Table/customTableUtilities";

const dueColorThreshold = 1_000;

type CapitalCallsRow = {
  path: Array<string>;
  id: string;
  fundName: string;
  deadline: Date;
  called: Money;
  due: Money;
  isPaid: boolean;
  currency: string;
  isPendingFx: boolean;

  calledEur: number;
  calledUsd: number;
  dueEur: number;
  dueUsd: number;

  link: DocumentLinkStructure;
};

type Props = {
  capitalCalls: ReadonlyArray<SingleCapitalCall>;
};

const PendingFxChip: React.FC = () => (
  <TooltipWrapper text="We have received funds for this capital call in a currency other than the one specified. The amount due will be updated to reflect the equivalent value once the funds have been converted into the designated currency for the capital call. Please note that the final amount due will be adjusted based on the current exchange rate at the time of conversion.">
    <Chip
      size="small"
      backgroundColor="accentLight"
      textColor="invariantWhite"
      radius="normal"
    >
      PENDING FX
    </Chip>
  </TooltipWrapper>
);

/**
 * The capital calls section in the transactions page.
 */
export const CapitalCalls: React.FC<Props> = ({ capitalCalls }) => {
  const palette = useHeritageV2Palette();
  const investorId = useInvestor()?.id;

  const { rows, aggregatedFunds, fundAggregationIdToName } = useMemo(() => {
    const rows: Array<CapitalCallsRow> = [];
    const aggregatedFunds: Record<string, Set<string>> = {};
    const fundAggregationIdToName: Record<string, string> = {};

    capitalCalls.forEach((capitalCall) => {
      const deadlineDate = capitalCall.deadlineDate;
      const capitalCallAggregationId = `${deadlineDate}`;

      if (!deadlineDate || !investorId) return;

      if (aggregatedFunds[capitalCallAggregationId] === undefined)
        aggregatedFunds[capitalCallAggregationId] = new Set();

      // We are going to use this record to aggregate transactions with the same
      // fund and currency in this LP Capital Call.
      const aggregatedTransactions: Record<string, CapitalCallsRow> = {};

      capitalCall.transactions.forEach((transaction) => {
        const fundName = transaction.allocation.asset.name;

        // FIXME: These types should be handled in a better way in order
        // to avoid the explicit cast.
        const currency = transaction.allocation.currency as "EUR" | "USD";

        const fundAggregationId = generateCapitalCallFundAggregationId(
          fundName,
          currency,
        );

        const called = new Money({
          [currency]: transaction.calledAmount,
        });

        const dueAmountRoundedNonNegative = Math.max(
          Math.round(
            (transaction.calledAmount - transaction.paidAmount) * 100,
          ) / 100,
          0,
        );

        const due = new Money({
          [currency]: dueAmountRoundedNonNegative,
        });

        const current: CapitalCallsRow | undefined =
          aggregatedTransactions[fundAggregationId];

        // If we already have a row with this fund and currency
        // then we just aggregate it to the existing value.
        if (current) {
          current.called = current.called.plus(called);
          current.calledEur += called.EUR ?? 0;
          current.calledUsd += called.USD ?? 0;

          current.due = current.due.plus(due);
          current.dueEur += due.EUR ?? 0;
          current.dueUsd += due.USD ?? 0;

          return;
        }

        // This will be used while rendering the single groups to make
        // the group name, which is composed by a comma separated list
        // of funds names for a specific capital call date.
        aggregatedFunds[capitalCallAggregationId].add(fundName);

        // This will be used while rendering each group item (leaf) in order
        // to avoid showing the aggregation fund id to the user.
        fundAggregationIdToName[fundAggregationId] = fundName;

        aggregatedTransactions[fundAggregationId] = {
          path: [capitalCallAggregationId, fundAggregationId],
          id: `${capitalCallAggregationId}-${fundAggregationId}`,
          fundName: fundName,
          deadline: new Date(deadlineDate),
          called,
          due,
          isPaid: !transaction.isPendingFx && transaction.isPaid,
          currency,
          isPendingFx: transaction.isPendingFx,
          calledEur: called.EUR ?? 0,
          calledUsd: called.USD ?? 0,
          dueEur: due.EUR ?? 0,
          dueUsd: due.USD ?? 0,

          link: {
            kind: "download",
            link: config.serverBaseUrl + capitalCall.document.link,
            onClick: () => {
              tracker.trackEvent({
                name: "ShowCapitalCallDocument",
                payload: {
                  capitalCallId: capitalCall.uuid,
                  investorId: investorId,
                },
              });
            },
          },
        };
      });

      rows.push(...Object.values(aggregatedTransactions));
    });

    return { rows, aggregatedFunds, fundAggregationIdToName };
  }, [capitalCalls, investorId]);

  const columns = useMemo<Array<TableColumn<CapitalCallsRow>>>(
    () => [
      {
        headerName: "Fund name",
        field: "fundName",
        flex: 1,
      },

      {
        headerName: "Deadline",
        field: "deadline",
        type: "date",
        minWidth: 150,
        valueFormatter: (value, { id }, _, api) => {
          const valueToDisplay = match([value, id])
            .with([P.nullish, P.nullish], () => null)
            .with([P.instanceOf(Date), P.nullish], ([dateValue]) =>
              formatDateDisplay1(dateValue as Date),
            )
            .with([P.instanceOf(Date), P.string], ([dateValue, rowId]) => {
              const row = api.current.getRowNode(rowId);

              // Hide the date if this is a leaf node.
              if (row?.type === "leaf") return null;

              return formatDateDisplay1(dateValue as Date);
            })
            .otherwise(() => tableFallbackValue);

          return valueToDisplay;
        },
        flex: 1,
      },
      {
        headerName: "Called",
        type: "Money",
        field: "called",
        flex: 1,
        sortable: false,
      },
      {
        headerName: "Called  (EUR)",
        type: "number",
        field: "calledEur",
        sortable: false,
      },
      {
        headerName: "Called  (USD)",
        type: "number",
        field: "calledUsd",
        sortable: false,
      },
      {
        headerName: "Due",
        type: "Money",
        field: "due",
        renderCell: ({
          value,
          formattedValue,
          row: { isPendingFx: isRowPendingFx },
          api,
          id,
        }: GridRenderCellParams<CapitalCallsRow>) => {
          const row = api.getRowNode(id);

          if (typeof formattedValue !== "string" || row === null) {
            return null;
          }

          // if this is a group row, we need to check if any of the children
          // have pending fx
          const isChildPendingFx =
            row.type === "group" &&
            row.children.some(
              (child) => api.getRow<CapitalCallsRow>(child)?.isPendingFx,
            );

          // display the pending fx chip if either the row or any of the children have pending fx
          const isPendingFx = isRowPendingFx || isChildPendingFx;

          const parsed = parseSimpleMoney(value);

          const color =
            !parsed || parsed.amount >= dueColorThreshold
              ? palette.text900
              : palette.neutral500;

          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
              spacing={0}
            >
              <Typography variant="tdLabel" color={color} className="truncate">
                {formattedValue}
              </Typography>
              {isPendingFx && <PendingFxChip />}
            </Stack>
          );
        },

        flex: 1,
        sortable: false,
      },
      {
        headerName: "Due  (EUR)",
        type: "number",
        field: "dueEur",
        sortable: false,
      },
      {
        headerName: "Due  (USD)",
        type: "number",
        field: "dueUsd",
        sortable: false,
      },
      {
        headerName: "",
        field: "link",
        type: "custom",
        width: 55,
        customColumnKind: {
          kind: "documentLink",
          generateLink: ({ value }) => value as DocumentLinkStructure,
          hideOnNodeGroup: "leaf",
        },
      },
    ],
    [palette],
  );

  const groupingColumn = useMemo<TableGroupingColumn<CapitalCallsRow>>(
    () => ({
      flex: 3,
      minWidth: 200,
      headerName: "Funds",
      valueFormatter: (value) => {
        // If the value is not `undefined` then this is a
        // group and we should format it.
        if (typeof value !== "undefined") {
          return typeof value === "string" && aggregatedFunds[value]
            ? (formatAggregatedFundName(aggregatedFunds[value]) ??
                tableFallbackValue)
            : tableFallbackValue;
        }

        // If otherwise the value IS `undefined` then this is
        // a normal row, and we should return the base value.
        return value;
      },
      formatLeafValue: (value) =>
        typeof value === "string" ? fundAggregationIdToName[value] : value,
    }),
    [aggregatedFunds, fundAggregationIdToName],
  );

  return (
    <PageTable
      title="Capital calls"
      kind="table"
      columns={columns}
      rows={rows}
      groupingColumn={groupingColumn}
      aggregationModel={{
        deadline: "max",
        called: moneySumAggregationLabel,
        due: moneySumAggregationLabel,
        link: customFirstValAggregationLabel,
      }}
      visibilityModel={{
        fundName: false,
        dueUsd: false,
        dueEur: false,
        calledUsd: false,
        calledEur: false,
      }}
      defaultSortingModel={{
        field: "deadline",
        sort: "desc",
      }}
      exportHidden
      treeData
      downloadExcel
      excelGetRowsToExport={(apiRef) => {
        const rows = gridDataRowIdsSelector(apiRef);
        return rows.filter((id) => id !== undefined);
      }}
    />
  );
};
