import { Paper, Stack, Typography } from "@mui/material";
import { startOfQuarter } from "date-fns";
import { option as O } from "fp-ts";
import React, { useMemo } from "react";
import { useQuery } from "urql";
import {
  FeatureFlag,
  PortfolioAmountsChartDocument,
  PortfolioAmountsChartQuery,
  PortfolioAmountsChartQueryVariables,
} from "../../../../generated/urql";
import { useInvestor } from "../../../../hooks/useInvestor";
import { useRetrieveCutoffs } from "../../../../hooks/useRetrieveCutoffs";
import { formatCutoffDate, getTodayCutoff } from "../../../../utils/cutoff";
import { disableSuspenseContext } from "../../../../utils/queries";
import { Box } from "../../../core/v2/Box/Box";
import { SkeletonLoader } from "../../../core/v2/Loader/SkeletonLoader";
import { NoDataPlaceholder } from "../../../core/v2/NoDataPlaceholder/NoDataPlaceholder";
import { UnderlineH3 } from "../../../core/v2/UnderlineH3/UnderlineH3";
import {
  PortfolioAmountsChart,
  PortfolioAmountsChartNavPoint,
  PortfolioAmountsChartPaidInPoint,
} from "../PortfolioAmountsChart/PortfolioAmountsChart";
import {
  QuarterSelect,
  useQuarterSelectManager,
} from "../QuarterSelect/QuarterSelect";
import { PortfolioOverviewStats } from "./PortfolioOverviewStats";
import { PortfolioOverviewStatsLoader } from "./PortfolioOverviewStatsLoader";
import { useRecoilValue } from "recoil";
import { enableNavV2 } from "../../../../state/developer";
import { PortfolioDiversificationPieChart } from "../PortfolioDiversificationChart/PortfolioDiversificationChart";
import Grid from "@mui/material/Unstable_Grid2";

/**
 * Text to display when the portfolio section is empty.
 */
export const portfolioEmptyQuarterText =
  "Nothing to show in the current quarter";

/**
 * Display an overview of the portfolio.
 */
export const PortfolioOverview: React.FC = () => {
  const {
    selectedQuarter,
    setSelectedQuarter,
    maybePortfolioSituation,
    loading: statsLoading,
  } = useQuarterSelectManager();

  const investorId = useInvestor()?.id;
  const {
    confirmedCutoffs,
    draftCutoffs,
    fetching: cutoffsLoading,
  } = useRetrieveCutoffs();

  const cutoffs = useMemo(
    () => [...confirmedCutoffs, ...draftCutoffs],
    [confirmedCutoffs, draftCutoffs],
  );

  const isNavV2Enabled = useRecoilValue(enableNavV2);

  const [{ data, fetching: amountsChartLoading }] = useQuery<
    PortfolioAmountsChartQuery,
    PortfolioAmountsChartQueryVariables
  >({
    query: PortfolioAmountsChartDocument,
    variables: {
      investorId: investorId ?? "",
      cutoffs: cutoffs.map(formatCutoffDate) ?? [],
      flags: [...(isNavV2Enabled ? [FeatureFlag.EnableNavV2] : [])],
    },
    pause: !investorId || cutoffs.length === 0,
    context: disableSuspenseContext,
  });

  const isPortfolioEmpty = O.isNone(maybePortfolioSituation) && !statsLoading;

  const isPortfolioLoading =
    statsLoading ||
    O.isNone(maybePortfolioSituation) ||
    cutoffsLoading ||
    amountsChartLoading;

  const { navPoints, paidInPoints } = useMemo(() => {
    const situations = data?.investor.portfolioSituation ?? [];

    const navPoints = new Array<PortfolioAmountsChartNavPoint>();
    const paidInPoints = new Array<PortfolioAmountsChartPaidInPoint>();

    for (const {
      cutoffDate,
      totals: { navUsd, paidInUsd },
    } of situations) {
      const parsedCutoffDate = startOfQuarter(new Date(cutoffDate));

      navPoints.push({
        x: parsedCutoffDate,
        y: navUsd,
        paidIn: paidInUsd,
        label: "",
      });

      paidInPoints.push({
        x: parsedCutoffDate,
        y: paidInUsd,
      });
    }

    return {
      navPoints,
      paidInPoints,
    };
  }, [data]);

  const activeCutoff = useMemo(() => {
    const todayCutoff = formatCutoffDate(getTodayCutoff());

    return todayCutoff !== selectedQuarter
      ? new Date(selectedQuarter)
      : undefined;
  }, [selectedQuarter]);

  const isActiveCutoffDraft = useMemo(
    () =>
      !!draftCutoffs.find(
        (cutoff) => formatCutoffDate(cutoff) === selectedQuarter,
      ),
    [draftCutoffs, selectedQuarter],
  );

  const headingSectionRender = useMemo(
    () => (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        mt={-2}
      >
        <Box flex="0 0 auto" mt={2}>
          <Typography variant="h1">Portfolio Overview</Typography>
        </Box>

        <Box flex={{ xs: "0 0 100%", md: "0 0 230px" }} mt={2}>
          <QuarterSelect
            value={selectedQuarter}
            onChange={setSelectedQuarter}
          />
        </Box>
      </Stack>
    ),
    [selectedQuarter, setSelectedQuarter],
  );

  if (isPortfolioEmpty)
    return (
      <Stack direction="column" spacing={2} height="100%">
        <Box flex="0 0 auto">{headingSectionRender}</Box>

        <Box flex="1 1 100%">
          <NoDataPlaceholder message={portfolioEmptyQuarterText} />
        </Box>
      </Stack>
    );

  return (
    <>
      <Box>{headingSectionRender}</Box>

      <Box mt={2}>
        {!statsLoading && O.isSome(maybePortfolioSituation) ? (
          <PortfolioOverviewStats
            quarterPortfolioSituation={maybePortfolioSituation.value}
            isDraft={isActiveCutoffDraft}
          />
        ) : (
          <PortfolioOverviewStatsLoader />
        )}
      </Box>

      <Box mt={2}>
        {!isPortfolioLoading ? (
          <Paper>
            <Box p={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h3">
                  <UnderlineH3 color="chart100">NAV</UnderlineH3> and{" "}
                  <UnderlineH3 color="chart700">Paid-in</UnderlineH3> over time
                </Typography>

                <Typography variant="formLabel">
                  Everything in <strong>&apos;000 $</strong>
                </Typography>
              </Stack>

              <Box mt={1}>
                <PortfolioAmountsChart
                  navData={navPoints}
                  paidInData={paidInPoints}
                  activeCutoff={activeCutoff}
                />
              </Box>
            </Box>
          </Paper>
        ) : (
          <SkeletonLoader
            size={{
              // Hardcoded aspect ratio for the whole
              // box in which the chart is positioned.
              aspectRatio: "1966 / 624",
            }}
          />
        )}
      </Box>

      <Box mt={2}>
        {!isPortfolioLoading ? (
          <Grid container spacing={2}>
            <Grid sm={12} md={4}>
              <Paper sx={{ height: "100%" }}>
                <Box p={2}>
                  <Typography variant="h3">Strategies</Typography>

                  <Box mt={2}>
                    <PortfolioDiversificationPieChart
                      diversifications={
                        maybePortfolioSituation.value
                          .diversificationByAllocationAmount
                      }
                      diversificationKind="strategies"
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid sm={12} md={4}>
              <Paper sx={{ height: "100%" }}>
                <Box p={2}>
                  <Typography variant="h3">Geographies</Typography>

                  <Box mt={2}>
                    <PortfolioDiversificationPieChart
                      diversifications={
                        maybePortfolioSituation.value
                          .diversificationByAllocationAmount
                      }
                      diversificationKind="geographies"
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid sm={12} md={4}>
              <Paper sx={{ height: "100%" }}>
                <Box p={2}>
                  <Typography variant="h3">Industries</Typography>

                  <Box mt={2}>
                    <PortfolioDiversificationPieChart
                      diversifications={
                        maybePortfolioSituation.value
                          .diversificationByAllocationAmount
                      }
                      diversificationKind="industries"
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <SkeletonLoader size={{ height: 409 }} />
        )}
      </Box>
    </>
  );
};
