import React, { useMemo } from "react";
import { NavigationTabs } from "../../../core/v2/NavigationTabs/NavigationTabs";
import { HeritageOverviewDocuments } from "../MarketingOverviewDocuments/HeritageOverviewDocuments";
import { SingleDocument } from "../data/SingleDocument";
import { InvestmentsDocuments } from "../InvestmentsDocuments/InvestmentsDocuments";
import { function as F, readonlyArray as ROA } from "fp-ts";
import { Box } from "../../../core/v2/Box/Box";
import { QuarterlyReportsDocuments } from "../QuarterlyReportsDocuments/QuarterlyReportsDocuments";
import { CapitalCallsDocuments } from "../CapitalCallsDocuments/CapitalCallsDocuments";
import { DocumentsCapitalCall } from "../data/DocumentsCapitalCall";
import { DocumentsDistribution } from "../data/DocumentsDistribution";
import { DistributionsDocuments } from "../DistributionsDocuments/DistributionsDocuments";

export const documentsCapitalCallsRouteName = "capital-calls";
export const documentsDistributionsRouteName = "distributions";

type Props = {
  documents: ReadonlyArray<SingleDocument>;
  hideQuarterlyReports?: boolean;
  capitalCalls?: ReadonlyArray<DocumentsCapitalCall>;
  distributions?: ReadonlyArray<DocumentsDistribution>;
};

/**
 * Main Data Room view component.
 */
export const DataRoomView: React.FC<Props> = ({
  capitalCalls,
  distributions,
  documents,
  hideQuarterlyReports,
}) => {
  const { platformDocuments, overviewDocuments } = useMemo(
    () =>
      F.pipe(
        documents,
        ROA.reduce(
          { platformDocuments: [], overviewDocuments: [] },
          (acc, document) => {
            const key =
              document.type === "PLATFORM"
                ? "platformDocuments"
                : "overviewDocuments";
            return {
              ...acc,
              [key]: [...acc[key], document],
            };
          },
        ),
      ),
    [documents],
  );

  const tabs = useMemo(
    () => [
      {
        route: "heritage-overview" as const,
        displayName: "Heritage Overview",
        component: <HeritageOverviewDocuments documents={platformDocuments} />,
      },
      {
        route: "investments" as const,
        displayName: "Investments",
        component: <InvestmentsDocuments documents={overviewDocuments} />,
      },
      ...(!hideQuarterlyReports
        ? [
            {
              route: "quarterly-reports" as const,
              displayName: "Quarterly Reports",
              component: <QuarterlyReportsDocuments />,
            },
          ]
        : []),
      ...(capitalCalls
        ? [
            {
              route: documentsCapitalCallsRouteName,
              displayName: "Capital Calls",
              component: <CapitalCallsDocuments capitalCalls={capitalCalls} />,
            },
          ]
        : []),
      ...(distributions
        ? [
            {
              route: documentsDistributionsRouteName,
              displayName: "Distributions",
              component: (
                <DistributionsDocuments distributions={distributions} />
              ),
            },
          ]
        : []),
    ],
    [
      platformDocuments,
      overviewDocuments,
      capitalCalls,
      distributions,
      hideQuarterlyReports,
    ],
  );

  return (
    <Box>
      <NavigationTabs defaultTab="heritage-overview" tabs={tabs} />
    </Box>
  );
};
