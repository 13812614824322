import { Typography } from "@mui/material";
import React, { PropsWithChildren, useMemo } from "react";
import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import { IterableElement } from "type-fest";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Box } from "../Box/Box";
import { Chip } from "../Chip/Chip";

/**
 * This is a custom "wrapper router" used internally that will perform a
 * redirect when the user lands on the current relative _root_ path.
 * It's a manual fix in order to avoid a blank render caused by
 * a `<Navigate>` used inside `<AnimatePresence>`. This will execute
 * the redirect _before_ rendering the `<AnimatePresence>` component.
 */
const RootToDefaultRouter: React.FC<{ to: string } & PropsWithChildren> = ({
  to,
  children,
}) => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={to} replace />} />
      <Route path="*" element={children} />
    </Routes>
  );
};

type SingleTab<R extends string> = {
  route: R;
  displayName: string;
  component?: React.ReactNode;
  badgeContent?: string;
};

type Props<R extends string, T extends Array<SingleTab<R>>> = {
  tabs: T;
  defaultTab: IterableElement<T>["route"];
};

/**
 * A component that will render a list of tabs to the top and
 * the relative open page at the bottom. The tabs are managed
 * as routes, so the "parent" route in which this component resides
 * should have a `path` like `/*` in order to enable sub-routes.
 */
export const NavigationTabs = <
  R extends string,
  T extends Array<SingleTab<R>>,
>({
  tabs,
  defaultTab,
}: Props<R, T>) => {
  const paletteV2 = useHeritageV2Palette();

  // This will filter out all the "empty" tabs.
  const tabsToShow = useMemo(() => tabs.filter((t) => !!t.component), [tabs]);

  const tabActiveColor = paletteV2.accent;
  const tabInactiveColor = paletteV2.neutral700;

  return (
    <RootToDefaultRouter to={defaultTab}>
      <Box height="100%" display="flex" flexDirection="column" flex="0 0 auto">
        <Box
          flex="0 0 auto"
          flexDirection="row"
          display="flex"
          borderBottom={1}
          borderColor={paletteV2.neutral500}
          sx={{ overflowX: "auto", overflowY: "hidden" }}
        >
          {tabsToShow.map((tab, index) => (
            <Box
              key={tab.route}
              pl={index > 0 ? 2 : undefined}
              style={{ marginBottom: "-1px" }}
            >
              <NavLink to={tab.route} style={{ textDecoration: "none" }}>
                {({ isActive }) => (
                  <Box
                    textAlign="center"
                    borderBottom={isActive ? 2 : 0}
                    borderColor={tabActiveColor}
                    paddingY={1}
                    paddingX={2}
                    minWidth={80}
                    display="flex"
                    alignItems="center"
                    gap={0}
                  >
                    <Typography
                      color={isActive ? tabActiveColor : tabInactiveColor}
                      variant="menuItem"
                      whiteSpace="nowrap"
                    >
                      {tab.displayName}
                    </Typography>

                    {tab.badgeContent ? (
                      <Box
                        sx={{
                          opacity: isActive ? 1 : 0.5,
                        }}
                        flex="0 0 auto"
                      >
                        <Chip
                          size="small"
                          backgroundColor="accent"
                          textColor="invariantWhite"
                          radius="rounded"
                        >
                          {tab.badgeContent}
                        </Chip>
                      </Box>
                    ) : undefined}
                  </Box>
                )}
              </NavLink>
            </Box>
          ))}
        </Box>

        <Box paddingTop={4} flex="1 1 100%">
          <Routes>
            {tabsToShow.map((tab) => (
              <Route
                key={tab.route}
                path={tab.route + "/*"}
                element={<Box height="100%">{tab.component}</Box>}
              />
            ))}

            <Route
              key="everything-else"
              path="*"
              element={<Navigate to={defaultTab} replace />}
            />
          </Routes>
        </Box>
      </Box>
    </RootToDefaultRouter>
  );
};
