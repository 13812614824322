/* eslint-disable react/no-children-prop */
import React, { Suspense } from "react";

import {
  BrowserRouter,
  matchPath,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import { RecoilRoot } from "recoil";

import {
  cacheExchange,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  Provider,
} from "urql";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { datadogRum } from "@datadog/browser-rum";

import { AppLayoutLoader, useLayoutContext } from "../LayoutRoute/LayoutRoute";

import { GenericErrorBox } from "../../components/commons/GenericErrorBox";

import { config } from "../../utils/config";
import { ErrorBoundary } from "../../lib/utils/datadog/ErrorBoundary";

import { AssetRoute } from "../AssetRoute/AssetRoute";
import { AssetsRoute } from "../AssetsRoute/AssetsRoute";
import { CashflowSimulatorRoute } from "../CashflowSimulatorRoute/CashflowSimulatorRoute";
import { DocumentsRoute } from "../DocumentsRoute/DocumentsRoute";
import { IndexRoute } from "../IndexRoute/IndexRoute";
import { ManageInvestorRoute } from "../ManageInvestorRoute/ManageInvestorRoute";
import { PortfolioRoute } from "../PortfolioRoute/PortfolioRoute";
import { SharedDocumentsRoute } from "../SharedDocumentsRoute/SharedDocumentsRoute";
import { TransactionsRoute } from "../TransactionsRoute/TransactionsRoute";
import { UserProfileRoute } from "../UserProfileRoute/UserProfileRoute";

import { FullPageLoader } from "../../components/core/Loader/FullPageLoader";

import {
  PasswordNotChangedGuard,
  PendingInvestorGuard,
  setDefaultDashboardPage,
} from "../../utils/navigation";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import dateLocale from "date-fns/locale/en-US";
import { SuggestPasskeyActivation } from "../../components/commons/SuggestPasskeyActivation";
import { InvestmentsView } from "../../components/investments/v2/InvestmentsView/InvestmentsView";
import {
  documentsCapitalCallsRouteName,
  documentsDistributionsRouteName,
} from "../../components/documents/v2/DataRoomView/DataRoomView";

const documentsPath = `investor/:investorId/about-heritage/*`;
const documentsCapitalCallsPath = `investor/:investorId/about-heritage/${documentsCapitalCallsRouteName}`;
const documentsDistributionsPath = `investor/:investorId/about-heritage/${documentsDistributionsRouteName}`;
const bankAccountsPath = `investor/:investorId/transactions/*`;

const NotFoundErrorBox = () => {
  const navigate = useNavigate();
  return (
    <GenericErrorBox
      title={"Not Found"}
      body={"The page your are looking for was not found"}
      // go to the origin (index)
      button={{ onClick: () => navigate(""), title: "Home" }}
    />
  );
};

// Various security guarda that applies to all the
// routes except for the ones in the profile.
const ComposedLayoutGuard = () => {
  const context = useLayoutContext();

  return (
    <PasswordNotChangedGuard>
      <PendingInvestorGuard>
        <Outlet context={context} />
      </PendingInvestorGuard>
    </PasswordNotChangedGuard>
  );
};

// Various overlays (modals, checks, ...) that are displayed
// on top of the layout.
const LayoutOverlays = () => {
  const context = useLayoutContext();
  return (
    <>
      <Outlet context={context} />
      <SuggestPasskeyActivation />
    </>
  );
};

// FIXME: see if there's a way to automatically generate this type
export type RouteClasses =
  | "IndexRoute"
  | "AppLayoutLoader"
  | "PortfolioRoute"
  | "InvestmentsRoute"
  | "InvestmentsAssetRoute"
  | "CashflowSimulatorRoute"
  | "AssetsRoute"
  | "AssetRoute"
  | "DocumentsRoute"
  | "TransactionsRoute"
  | "UserProfileRoute"
  | "ManageInvestorRoute";

export const RootRoute: React.FC = () => {
  // create the graphql client
  const graphqlClient = createClient({
    url: config.graphQLUrlPath,
    requestPolicy: "cache-and-network", // See https://formidable.com/open-source/urql/docs/basics/document-caching/#request-policies
    fetchOptions: {
      credentials: "include",
      headers: {
        // This is a custom header used to prevent CSRF attacks, svc-platform
        // will check this header and if it's not present it will return a 403
        // see https://security.stackexchange.com/questions/23371/csrf-protection-with-custom-headers-and-without-validating-token
        "x-heritage-action": "graphql",
      },
    },
    // note the order of the exchanges is important
    exchanges: [
      dedupExchange,
      cacheExchange,
      errorExchange({
        onError: (error) => {
          const status = error.response?.status as unknown;
          const url = new URL(window.location.href);

          if (status === 401 || status === 403) {
            const documentsCapitalCallsPathMatches = matchPath(
              `${config.pathPrefix}/${documentsCapitalCallsPath}`,
              url.pathname,
            );

            const documentsDistributionsPathMatches = matchPath(
              `${config.pathPrefix}/${documentsDistributionsPath}`,
              url.pathname,
            );

            const documentsPathMatches = matchPath(
              `${config.pathPrefix}/${documentsPath}`,
              url.pathname,
            );

            const bankAccountsPathMatches = matchPath(
              `${config.pathPrefix}/${bankAccountsPath}`,
              url.pathname,
            );

            // We save the necessary pages in the local storage
            // to redirect the user after the login.
            if (documentsCapitalCallsPathMatches?.params?.investorId)
              setDefaultDashboardPage({
                page: "VDR/CapitalCalls",
                investorId: documentsCapitalCallsPathMatches.params.investorId,
                queryString: url.search,
              });
            else if (documentsDistributionsPathMatches?.params?.investorId)
              setDefaultDashboardPage({
                page: "VDR/Distributions",
                investorId: documentsDistributionsPathMatches.params.investorId,
                queryString: url.search,
              });
            else if (documentsPathMatches?.params?.investorId)
              setDefaultDashboardPage({
                page: "VDR",
                investorId: documentsPathMatches.params.investorId,
                queryString: url.search,
              });
            else if (bankAccountsPathMatches?.params?.investorId)
              setDefaultDashboardPage({
                page: "BankAccounts",
                investorId: bankAccountsPathMatches.params.investorId,
                queryString: url.search,
              });

            window.location.href = config.loginPageUrl;
          } else {
            datadogRum.addError(error);
          }
        },
      }),
      fetchExchange,
    ],
    suspense: true,
  });

  // create the rest client
  const restClient = new QueryClient({
    defaultOptions: {
      queries: {
        useErrorBoundary: true,
      },
      mutations: {
        useErrorBoundary: true,
      },
    },
  });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateLocale}
    >
      <BrowserRouter>
        <RecoilRoot>
          <Provider value={graphqlClient}>
            <QueryClientProvider client={restClient}>
              <ErrorBoundary fallback={<GenericErrorBox />}>
                <Suspense fallback={<FullPageLoader />}>
                  <Routes>
                    {/* IMPORTANT: each element must have a unique
                                key and that key is used to give a name to the
                                route, to be used with the useRouteResolver
                                hook - also each key should be added to the
                                RouteClasses type above. */}
                    <Route
                      path={config.pathPrefix}
                      element={<AppLayoutLoader key="AppLayoutLoader" />}
                    >
                      <Route element={<LayoutOverlays />}>
                        <Route element={<ComposedLayoutGuard />}>
                          <Route
                            path={documentsPath}
                            element={<DocumentsRoute key="DocumentsRoute" />}
                          />

                          <Route
                            path="my-portfolio/:investorId/*"
                            element={<PortfolioRoute key="PortfolioRoute" />}
                          />

                          <Route
                            path="investments/:investorId/*"
                            element={<InvestmentsView key="InvestmentsRoute" />}
                          />

                          <Route
                            path="investments/:investorId/funds/:assetId/*"
                            element={
                              <AssetRoute
                                fromSection="assets"
                                key="InvestmentsAssetRoute"
                              />
                            }
                          />

                          <Route
                            path="investor/:investorId/cashflow-simulator"
                            element={
                              <CashflowSimulatorRoute key="CashflowSimulatorRoute" />
                            }
                          />
                          <Route
                            path="investor/:investorId/funds/status/*"
                            element={<AssetsRoute key="AssetsRoute" />}
                          />
                          <Route
                            path="investor/:investorId/funds/:assetId/*"
                            element={
                              <AssetRoute
                                fromSection="investments"
                                key="AssetRoute"
                              />
                            }
                          />
                          <Route
                            path="investor/:investorId/transactions/*"
                            element={
                              <TransactionsRoute key="TransactionsRoute" />
                            }
                          />

                          <Route
                            path="me/investor/:investorId"
                            element={
                              <ManageInvestorRoute key="ManageInvestorRoute" />
                            }
                          />
                        </Route>

                        <Route
                          path="investor/:investorId/me/account/*"
                          element={
                            <UserProfileRoute key="UserProfileRouteV2" />
                          }
                        />

                        <Route
                          path="me/account"
                          element={<UserProfileRoute key="UserProfileRoute" />}
                        />

                        <Route
                          index
                          element={<IndexRoute key="IndexRoute" />}
                        />

                        {/* fallback for not handled paths */}
                        <Route path={"*"} element={<NotFoundErrorBox />} />
                      </Route>
                    </Route>

                    <Route
                      path="/shared/investor/:investorId/:token/*"
                      element={
                        <SharedDocumentsRoute key="SharedDocumentsRoute" />
                      }
                    />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </QueryClientProvider>
          </Provider>
        </RecoilRoot>
      </BrowserRouter>
    </LocalizationProvider>
  );
};
