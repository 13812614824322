import React, { useMemo, useState } from "react";
import { PageTable } from "../../../core/v2/PageTable/PageTable";
import {
  DocumentLinkStructure,
  TableColumn,
} from "../../../core/v2/Table/Table";
import { useInvestor } from "../../../../hooks/useInvestor";
import { array as A, string as S, function as F, option as O } from "fp-ts";
import { tracker } from "../../../../utils/tracker";
import { config } from "../../../../utils/config";
import { Box } from "../../../core/v2/Box/Box";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { DocumentsDistribution } from "../data/DocumentsDistribution";
import { HighlightedDocument } from "../HighlightedDocument/HighlightedDocument";
import { useHighlightedDocument } from "../utils/highlightDocument";

const retrieveVehicle = (distribution: DocumentsDistribution) => {
  const vehicles = distribution.transactions.map(
    (transaction) => transaction.allocation.vehicle.name,
  );

  const vehicle = F.pipe(vehicles, A.uniq(S.Eq), A.head, O.toUndefined);

  return vehicle;
};

type DistributionsDocumentsRow = {
  id: string;
  title: string;
  link: DocumentLinkStructure;
  valueDate?: Date;
  vehicle?: string;
  isNew?: boolean;
};

type Props = {
  distributions: ReadonlyArray<DocumentsDistribution>;
};

export const DistributionsDocuments: React.FC<Props> = ({ distributions }) => {
  const investor = useInvestor();
  const highlightedDocumentId = useHighlightedDocument();
  const highlightedDocument = useMemo(
    () => distributions.find((c) => c.uuid === highlightedDocumentId),
    [highlightedDocumentId, distributions],
  );

  // Handle the local state for read documents. This is used to
  // update the `New` label after clicking on the download button.
  const [readDocuments, setReadDocuments] = useState<Record<string, true>>({});

  const palette = useHeritageV2Palette();

  const columns = useMemo<Array<TableColumn<DistributionsDocumentsRow>>>(
    () => [
      {
        headerName: "Title",
        field: "title",
        customColumnKind: {
          kind: "withInlineComponent",
          generateCell: ({ row, value }) => {
            if (!row) return {};

            // A document is new if it's not present in
            // `readDocuments` and `isNew` is true.
            const isNew = row.isNew && !readDocuments[row.id];

            return {
              prefixComponent: (
                <Box
                  width={7}
                  height={7}
                  mx={0}
                  borderRadius="100%"
                  bgcolor={isNew ? palette.accentLight : "transparent"}
                />
              ),
              value: `${value}`,
              boldText: isNew,
            };
          },
        },
        flex: 2,
        minWidth: 400,
      },
      {
        headerName: "Date",
        field: "valueDate",
        type: "date",
        minWidth: 150,
      },
      {
        headerName: "Partnership",
        field: "vehicle",
        minWidth: 150,
      },
    ],
    [readDocuments, palette],
  );

  const rows = useMemo(() => {
    const computedRows = Array<DistributionsDocumentsRow>();
    const investorId = investor?.id;

    if (!investorId) return computedRows;

    for (const distribution of distributions) {
      const valueDate = new Date(distribution.valueDate);
      const vehicle = retrieveVehicle(distribution);

      computedRows.push({
        id: distribution.uuid,
        title: `Distribution Notice for ${vehicle}`,
        link: {
          kind: "download",
          link: config.serverBaseUrl + distribution.document.link,
          onClick() {
            tracker.trackEvent({
              name: "ShowDistributionDocument",
              payload: {
                distributionId: distribution.uuid,
                investorId: investorId,
              },
            });

            // Set the document as read in the local state.
            setReadDocuments((curr) => ({
              ...curr,
              [distribution.uuid]: true,
            }));
          },
        },
        valueDate: valueDate || undefined,
        vehicle,
        isNew: !distribution.document.read,
      });
    }

    return computedRows;
  }, [distributions, investor, setReadDocuments]);

  return (
    <PageTable
      kind="documents-table"
      title="Distributions"
      columns={columns}
      rows={rows}
      defaultSortingModel={{ field: "valueDate", sort: "desc" }}
      renderAfterTitle={
        highlightedDocument && (
          <Box mb={3}>
            <HighlightedDocument
              vehicleName={retrieveVehicle(highlightedDocument) ?? "-"}
              downloadLink={
                config.serverBaseUrl + highlightedDocument.document.link
              }
              onDownload={() => {
                if (!investor) return;

                tracker.trackEvent({
                  name: "ShowDistributionDocument",
                  payload: {
                    distributionId: highlightedDocument.uuid,
                    investorId: investor.id,
                    fromHighlightBanner: true,
                  },
                });
              }}
            />
          </Box>
        )
      }
    />
  );
};
