import React, { useMemo, useState } from "react";
import { VictoryPie } from "victory";

import {
  array as A,
  function as F,
  number as N,
  ord as Ord,
  readonlyArray as RA,
} from "fp-ts";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Stack, Typography } from "@mui/material";
import { Box } from "../../../core/v2/Box/Box";
import { match } from "ts-pattern";

export type ChartsData = Array<{
  name: string;
  amount: number;
}>;

type Item = {
  name: string;
  amount: number;
};

type Diversification = {
  strategies: ReadonlyArray<Item>;
  geographies: ReadonlyArray<Item>;
  industries: ReadonlyArray<Item>;
};

type Props = {
  diversifications: Diversification;
  diversificationKind: keyof Diversification;
};

const sortByAmount = F.pipe(
  N.Ord,
  Ord.contramap((s: Item) => -s.amount),
);

export const PortfolioDiversificationPieChart: React.FC<Props> = ({
  diversifications,
  diversificationKind,
}) => {
  const palette = useHeritageV2Palette();
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined,
  );

  const colors = [
    palette.chart500,
    palette.chart400,
    palette.chart300,
    palette.chart200,
    palette.neutral300,
  ];

  // We take the first 4 items and group the rest in "Others"
  //TODO:  there is a proper industry category named "Other", should be reworked
  const dataset = useMemo(() => {
    return F.pipe(
      [...diversifications[diversificationKind]],
      A.sort(sortByAmount),
      A.splitAt(colors.length - 1),
      ([firstFour, others]) =>
        F.pipe(
          others,
          RA.reduce(0, (acc, curr) => acc + curr.amount),
          (othersAmount) => [
            ...firstFour,
            ...(othersAmount > 0
              ? [{ name: "Others", amount: othersAmount }]
              : []),
          ],
        ),
    );
  }, [colors.length, diversifications, diversificationKind]);

  return (
    <>
      <VictoryPie
        labels={() => null}
        data={dataset}
        x="name"
        y="amount"
        colorScale={colors}
        innerRadius={100}
        padAngle={2}
        animate={{
          duration: 1000,
          easing: "exp",
        }}
        events={[
          {
            target: "data",
            eventHandlers: {
              onMouseEnter: () => {
                return [
                  {
                    target: "data",
                    mutation({ index }) {
                      setSelectedIndex(index ?? undefined);
                    },
                  },
                ];
              },
              onMouseLeave: () => {
                setSelectedIndex(undefined);
              },
            },
          },
        ]}
      />

      <Stack direction="row" flexWrap="wrap" rowGap={0} gap={0}>
        {dataset.map((d, i) => {
          return (
            <Stack
              minWidth="40%"
              direction="row"
              key={d.name}
              alignItems="center"
              gap={0}
              sx={{
                opacity: match(selectedIndex)
                  .with(undefined, () => 1)
                  .with(i, () => 1)
                  .otherwise(() => 0.3),
                transition: "opacity 0.3s ease",
              }}
            >
              <Box height={20} width={10} bgcolor={colors[i]} />
              <Typography variant="formLabel" whiteSpace="nowrap">
                {d.name}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};
